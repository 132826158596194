.buttons {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
}
.buttons > span {
  color: #fff;
  float: right;
  font-size: 10px;
}
#media {
  margin: 10px;
}
#widget {
  margin: 10px;
}
#widget > div > div:first-child {
  margin-bottom: 10px;
}
#log {
  font-size: 9px;
  margin: 10px;
}
#log p {
  border-top: 1px solid #eee;
  margin: 0;
  padding: 3px 0;
}
